<template>
  <div>
    <div class="indexUser">
      <div>
        <!-- header  点击 -->
        <headers :userList="userList"></headers>
      </div>
      <div class="bg1 userBox">
        <div class="wrap bgw news">
          <div class="fl navtTab">
            <p @click="tab(2)" :class="{active:state == 2}">
              <img src="@/assets/image/news_Interrogation_icon@2x.png" alt />
              问诊消息
            </p>
            <p @click="tab(1)" :class="{active:state == 1}">
              <img src="@/assets/image/news_Order_icon@2x.png" alt />
              订单消息
            </p>
            <p @click="tab(5)"  :class="{active:state == 5}">
              <img src="@/assets/image/news_Settledin_icon@2x.png" alt />
              申请入驻
            </p>
<!--            <p @click="tab(3)" :class="{active:state == 3}">
              <img src="@/assets/image/fuzhen.png" alt />
              复诊消息
            </p> -->
            <p @click="tab(0)" :class="{active:state == 0}">
              <img src="@/assets/image/news_system_icon@2x.png" alt />
              系统消息
            </p>
          </div>
          <div class="fl txt">
            <div class="box" v-for="(v,i) in newsList" :key="i">
              <p class="time">{{v.add_time}}</p>
              <div class="textBox">
                <p class="title">{{v.title}}</p>
                <p>{{v.content}}</p>
              </div>
            </div>
            <p class="none">没有更多消息了</p>
          </div>
        </div>
      </div>
      <div>
        <publicBottom></publicBottom>
      </div>
    </div>
  </div>
</template>
<script>
import headers from "./header"; // 头部 TOP 和header
import publicBottom from "@/components/public/public_bottom"; //底部
import { usergetinfo } from "@/request/user";
import { message_list } from "@/request/user";
export default {
  components: {
    headers,
    publicBottom
  },
  data() {
    return {
      userList: [], // 个人信息
      newsList: [], //消息
      state: 2 //
    };
  },
  created() {
    let datas = localStorage.getItem("lzyyToken");
    this.state = this.$route.query.state;
    if(this.state == undefined) this.state = 2;
    let Athis = this;
    if (datas != null) {
      this.$tokens = datas;
    } else {
      this.$message.error("登录错误，请重新登录");
      setTimeout(function() {
        Athis.$router.replace({ path: "/land" });
      }, 1000);
    }

    this.init();
    this.start();
    // this.state=this.$route.query.state
  },
  methods: {
    tab(e) {
      this.state = e;
      this.start();
    },
    init() {
      //   个人信息
      usergetinfo({
        token: this.$tokens
      }).then(res => {
        this.userList = res.data;
      });
    },
    start() {
      message_list({
        token: this.$tokens,
        type: this.state
      }).then(res => {
        if (res.data.data != "") {
          let datas = res.data.data;
          for (let i = 0; i < datas.length; i++) {
            let times = datas[i].add_time;
            var date = new Date(times * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + "-";
            var M =
              (date.getMonth() + 1 < 10
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1) + "-";
            var D = date.getDate() + " ";
            var h = date.getHours() + ":";
            var m = date.getMinutes() + ":";
            var s = date.getSeconds();
            datas[i].add_time = Y + M + D + h + m + s;
          }
          this.newsList = datas;
          this.newsList.forEach(item => {
            if(item.type == 3) {
              item.content = JSON.parse(item.content).message
            } 
          })
          console.log(this.newsList)
        } else {
          this.newsList = [];
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.none {
  margin-top: 60px;
  text-align: center;
  margin-bottom: 40px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 500;
  color: rgba(97, 97, 97, 1);
}
.news {
  width: 1200px;
  height: 967px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(230, 230, 230, 1);
  .navtTab {
    width: 260px;
    height: 100%;
    background: rgba(101, 248, 213, 1);
    img {
      width: 50px;
      height: 50px;
      vertical-align: middle;
      margin-right: 18px;
    }
    p {
      height: 80px;
      line-height: 80px;
      text-indent: 18px;
      font-size: 20px;
      cursor: pointer;
    }
  }
  .txt {
    width: 938px;
    height: 967px;
    overflow-y: auto !important;
  }
  .txt::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  .txt::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    // border-radius: 10px;
    // background-color: #f5f5f5;
    background: #fff;
  }

  /*定义滑块 内阴影+圆角*/
  .txt::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color:  #f5f5f5;
  }
}
.box {
  margin-top: 40px;
  .time {
    text-align: center;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 500;
    color: rgba(97, 97, 97, 1);
    margin: auto;
    margin-bottom: 30px;
  }
  .textBox {
    width: 660px;
    background: #f5f5f5;
    border-radius: 10px;
    margin: auto;
    margin-bottom: 40px;
    p {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 500;
      color: rgba(97, 97, 97, 1);
      min-height: 80px;
      line-height: 40px;
      padding-left: 20px;
      padding-right: 20px;
    }
    .title {
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 1);
      height: 70px;
      line-height: 70px;
      border-bottom: 1px solid #e6e6e6;
    }
  }
}

.active {
  background: #b3fceb;
}
</style>